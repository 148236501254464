<template>
  <report-card class="report-step"
    :title="$t(info.name.toLowerCase())"
    :icon="`report-${info.name.toLowerCase()}`">
    <div v-if="report_step !== null">
      <li v-for="(step, index) of report_step.report_step_questions" :key="index">
        <p>{{step.question.question}}</p>
        <div v-if="!isCategorized(step.answers)">
          <step
            v-for="(answer, index) of step.answers"
            :key="index"
            :step="step"
            :answer="answer"
          />
        </div>
        <div v-else>
          <category-step
            :step="step"
            :answers="step.answers"
          />
        </div>
      </li>
    </div>
    <div v-else>
      <ui-button
        filled
        :context="info.color_name"
        v-on:click="goToReportStep(info.id)"
      >{{$t("fill_step")}}
      </ui-button>
    </div>
  </report-card>
</template>

<script>
import ReportCard from "./ReportCard"
import UiTag from "@/components/ui-modules/Tag"
import Step from "./Step/Step"
import CategoryStep from "./Step/CategoryStep"

import UiButton from "@/components/ui-modules/Buttons/Button";

export default {
  name: "ReportStep",
  components: {
    ReportCard,
    UiTag,
    Step,
    CategoryStep,
    UiButton

  },
  props: {
    report_step: {
      type: Object,
      default: null
    },
    info: {
      type: Object
    }
  },
  methods: {
    isCategorized(answers) {
      let is_categorized = false
      answers.forEach(answer => {
        if (answer.answer.category_id !== null) {
          is_categorized = true
          return true
        }
      });
      return is_categorized
    },
    goToReportStep(id) {
      this.$router.push({
        name: "step",
        query: {
          step_id: id
        },
      })
    }
  }
}
</script>

<style lang="scss">

.report-step {
  p {
    margin-bottom: 5px;
    font-size: 16px;
    color: $ui-text;
  }
  padding-top: 20px;
}

.report-step + .report-step {
  border-top: 1px solid $ui-light_grey;
}
.report-step:last-child {
  @media (max-width: 767px) {
    border-bottom: 1px solid $ui-light_grey;
  }
}

</style>