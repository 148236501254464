<template>
  <li class="user-cell">
    <avatar-initial
      :id="user.id"
      :name="user.firstname + ' ' + user.lastname"
    />
    <div class="user-info">
      <p class="profile-name">{{ user.firstname + " " + user.lastname }}</p>
      <p class="profile-role">{{ $t(user.role) }}</p>
    </div>
    <ui-button
      v-if="type === 'add'"
      context="grey"
      class="right-btn"
      smallSquare
      hoverShadow
      outline
      @click="$emit('action')"
      >
      <i class="fas fa-plus"></i>
    </ui-button>
    <ui-button
      v-else-if="type === 'remove'"
      context="red"
      class="right-btn"
      smallSquare
      hoverShadow
      outline
      @click="$emit('action')"
      >
      <i class="fas fa-trash-alt"></i>
    </ui-button>
  </li>
</template>

<script>
import AvatarInitial from "@/components/AvatarInitial";
import UiButton from "@/components/ui-modules/Buttons/Button";

export default {
  name: "UserCell",
  components: {
    AvatarInitial,
    UiButton
  },
  props: {
    user: {
      type: Object,
      default: null
    },
    type: {
      type: String, //TYPES: add, remove
      default: null
    }
  }
};
</script>

<style lang="scss">

.user-cell {
  li {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .avatar-circle {
    @include avatar(40px);
  }

  .right-btn {
    width: 24px;
    height: 24px;
    padding: 4px;
    margin-left: auto;
    font-size: 10px;
  }

  .user-info {
    margin-left: 16px;
    align-self: center;
    .profile-name {
      font-family: "Campton-Bold";
      margin: 0;
      color: $ui-text;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
    }
    .profile-role {
      font-family: "Campton-Book";
      color: $ui-subtitle;
      font-size: 14px;
      margin-bottom: 0px;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-transform: capitalize;
    }
  }
}
</style>
