const reportConclusions = [
  "accommodation_granted",
  "arbitration",
  "demotion_accused",
  "demotion_reporter",
  "increased_compensation",
  "litigation",
  "mediation",
  "new_policy",
  "new_roles_accused",
  "new_roles_reporter",
  "new_supervisor",
  "other",
  "promotion",
  "reduced_compensation",
  "reinstatement",
  "resignation_accused",
  "resignation_reporter",
  "settlement",
  "termination_accused",
  "termination_reporter",
  "update_policy",
  "management_coaching",
  "training_or_retraining",
  "transfer",
  "reassignment",
  "change_in_position",
  "change_in_schedule_shift",
  "change_in_reporting",
  "pip",
  "retaining_outside_investigator",
  "ombudsman",
  "open_door_policy",
  "non_admissible",
  "unsubstantiated",
];

const accusedOutcomes = [
  "attendee_banned",
  "attendee_removed",
  "counseling",
  "demotion",
  "no_disciplinary_action",
  "other",
  "pip",
  "resignation",
  "suspension",
  "termination",
  "training",
  "management_coaching",
  "training_or_retraining",
  "transfer",
  "reassignment",
  "change_in_position",
  "change_in_schedule_shift",
  "change_in_reporting",
];

export default {
  reportConclusions,
  accusedOutcomes,
};
