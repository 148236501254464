<template>
  <ui-loading v-if="loading" small/>
  <user-value v-else :user="user ? user : value" />
</template>

<script>
import UiLoading from "@/components/ui-modules/Loading";
import UserValue from "../TypeQueryValue/UserValue";
import userApi from "@/api/userApi";

export default {
  name: "UserQueryValue",
  components: {
    UiLoading,
    UserValue
  },
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      user: null
    }
  },
  async created() {
    if (this.value.firstname === undefined) {
      let id = parseInt(this.value.id)
      this.user = await this.getUser(id)
    }
  },
  methods: {
    async getUser(user_id) {
      this.loading = true
      try {
        const response = await userApi.getUserById(user_id)
        if (response.status === 200) {
          this.loading = false
          return response.data
        }
      } catch (error) {
          this.loading = false
          return null
      }
    }
  }
}
</script>

<style>

</style>