const search_categories = [{
  icon: "clipboard",
  title: "report_noun",
  cellType: "report",
  type: "report",
  multiple: false,
  query: "report_id"
},{
  icon: "clock",
  title: "status",
  cellType: "text",
  type: "status",
  query: "status_ids",
  multiple: true
},{
  icon: "check-double",
  title: "score",
  cellType: "text",
  type: "score",
  query: "scores",
  multiple: true
},{
  icon: "user-shield",
  title: "is_anonymous",
  cellType: "text",
  type: "anonymous",
  query: "is_anonymous",
  multiple: false
},{
  icon: "user-slash",
  title: "unassigned",
  cellType: "text",
  type: "unassigned",
  query: "has_assignee",
  multiple: false,
  dependency: "assignee"
},{
  icon: "user-friends",
  title: "assignee",
  cellType: "user",
  type: "assignee",
  query: "assignee_id",
  multiple: false,
  dependency: "unassigned"
},{
  icon: "calendar-alt",
  title: "submitted_since",
  cellType: "text",
  type: "date",
  query: "start_date",
  multiple: false
}, {
  icon: "tag",
  title: "tags_info.noun",
  cellType: "text",
  type: "tag",
  query: "tags",
  multiple: true
}
// ,{
//   icon: "gavel",
//   title: "reporter",
//   cellType: "user",
//   type: "reporter",
//   query: "user_id",
//   multiple: false
// },{
//   icon: "users",
//   title: "accused",
//   cellType: "user",
//   type: "accused",
//   query: "harasser_id",
//   multiple: false
// },{
//   icon: "comments",
//   title: "behavior",
//   cellType: "text",
//   type: "behavior",
//   query: "behavior_id",
//   multiple: false
// },{
//   icon: "map-pin",
//   title: "place",
//   cellType: "text",
//   type: "place",
//   query: "place_id",
//   multiple: false
// },{
//   icon: "map",
//   title: "location",
//   cellType: "location",
//   type: "location",
//   query: "location_id",
//   multiple: false
// }
]

export default search_categories;