import api from "./currentApi";
import constants from "./constants";

const loginUser = params => {
  return api.request_post(constants.LOGIN_URL, params);
};

const signUp = content => {
  return api.request_post(constants.SIGNUP_URL, content);
};

const deleteUser = () => {
  return api.request_logged_delete(constants.USER_URL);
};

const getUser = () => {
  return api.request_logged(constants.USER_URL);
};

const getUserById = uid => {
  return api.request_logged(`${constants.USER_URL}/${uid}`);
};

const updateUser = params => {
  return api.request_logged_put(constants.USER_URL, params);
};

const verifyMail = email => {
  return api.request_post(constants.VERIFY_MAIL_URL, { email: email });
};

const getPermissions = () => {
  return api.request_logged(constants.USER_PERMISSIONS_URL)
};

const postRecover = email => {
  return api.request_logged_post(constants.USER_RECOVER_URL, { email: email })
}

export default {
  loginUser,
  signUp,
  deleteUser,
  getUser,
  getUserById,
  updateUser,
  verifyMail,
  getPermissions,
  postRecover
};
