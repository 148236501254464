<template>
  <el-dropdown trigger="click" @command="onClick">
    <button-arrow
      :title="
        title
          ? $t(title) + ': ' + $t(this.content[current_index][element_title])
          : element_title
          ? $t(this.content[current_index][element_title])
          : $t(this.content[current_index])
      "
    />
    <el-dropdown-menu slot="dropdown" class="filter-popover">
      <el-dropdown-item
        v-for="(item, index) in this.content"
        :command="index"
        :key="index"
        class="text"
      >
        {{ element_title ? $t(item[element_title]) : $t(item) }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import ButtonArrow from "@/components/ui-modules/Buttons/ButtonArrow";
export default {
  name: "DropdownButton",
  components: {
    ButtonArrow
  },
  props: {
    title: {
      type: String
    },
    content: {
      type: Array
    },
    current_index: {
      type: Number
    },
    element_title: {
      type: String
    },
    onClick: {
      type: Function
    }
  }
};
</script>

<style lang="scss">

.filter-popover {
  border-radius: 13px;
  background-color: $ui-card;
  -webkit-box-shadow: $widget-shadow;
  -moz-box-shadow: $widget-shadow;
  box-shadow: $widget-shadow;

  .title {
    font-family: "Campton-Book";
    font-size: 20px;
    font-weight: 600;
    color: $ui-text;
    border-bottom: 0.5px solid $ui-light_grey;
  }

  .text {
    font-family: "Campton-Book";
    font-size: 15px;
    font-weight: 500;
    color: $ui-text;
  }

  .category {
    margin-top: 10px;
    .title {
      font-family: "Campton-Medium";
      font-size: 16px;
      font-weight: 500;
      color: $ui-text;
      border-bottom: none;
    }
    border-bottom: 0.5px solid $ui-light_grey;
  }

  .el-select .el-input__inner {
    background-color: $ui-card;
    &::placeholder {
      color: $ui-text;
    }
    color: $ui-text;
  }
}
</style>
