import api from "./currentApi";

const getTimelineElement = (custom_base_url, report_id, element, params) => {
  const url = `report/${report_id}/${element}`;
  return api.request_logged_custom(custom_base_url, url, params)
};

export default {
    getTimelineElement
};
