// store.js

import Vue from "vue";
import Vuex from "vuex";
import constants from "../api/constants";
import "babel-polyfill";
import api from "../api/api";
import userApi from "../api/userApi";
import { Translation } from "../utils/i18n/Translation";
import Device from "@/utils/end-to-end/device";
import url_utils from "../utils/url_utils";
import VueCookies from "vue-cookies";
import moment from 'moment';
// import Firebase from 'firebase'
// import 'Firebase/firestore'

Vue.use(Vuex);

const tokenName = url_utils.getTokenName();

export default new Vuex.Store({
  state: {
    auth: {
      token: VueCookies.get(tokenName) || null,
      user: localStorage.getItem("user") || null,
      role: {
        title: localStorage.getItem("title") || null,
        permissions: localStorage.getItem("permissions") || null
      }
    },
    language: localStorage.getItem("language") || null,
    e2e_enabled: false,
    device: new Device(),
    selected_companies: JSON.parse(localStorage.getItem("selected_companies")) || null,
    subcompanies: JSON.parse(localStorage.getItem("subcompanies")) || null
  },
  getters: {
    getUser: state => () => state.auth.user,
    getCurrentLanguage: state => () => state.language,
    // db: Firebase.firestore()
    getDevice: state => () => state.device,
    isE2Eenabled: state => () => state.e2e_enabled,
    getSelectedCompanies: state => () => state.selected_companies,
    getSubcompanies: state => () => state.subcompanies
  },
  mutations: {
    setAuthData: function(state, data) {
      state.auth.token = data.token;
      state.auth.user = JSON.stringify(data.user);
      state.auth.role.title = data.user.role;
      const tokenExpirationDays = url_utils.getTokenExpirationDays(data.token);
      VueCookies.set(tokenName, data.token, tokenExpirationDays + "d", null, null, true);
      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("title", data.user.role);
    },
    setUserData: function(state, data) {
      state.auth.user = JSON.stringify(data);
      state.auth.role.title = data.role;
      localStorage.setItem("user", JSON.stringify(data));
      localStorage.setItem("title", data.role);
    },
    removeAuthData: function(state) {
      state.auth.token = null;
      state.auth.user = null;
      state.auth.role.title = null;
      state.auth.role.permissions = null;
      state.selected_companies = null;
      state.subcompanies = null;
      if (state.e2e_enabled) {
        state.device.cleanup()
      }
      VueCookies.remove(tokenName);
      localStorage.removeItem("user");
      localStorage.removeItem("title");
      localStorage.removeItem("permissions");
      localStorage.removeItem("selected_companies");
      localStorage.removeItem("subcompanies");
    },
    setPermissions: function(state, data) {
      state.auth.role.permissions = data;
      localStorage.setItem("permissions", data);
    },
    setCurrentLanguage: function(state, data) {
      state.language = data;
      localStorage.setItem("language", data)
    },
    setDevice: function(state, data) {
      state.device = data;
    },
    setE2e_enabled: function(state, data) {
      state.e2e_enabled = data;
    },
    selectCompanies: function(state, data) {
      const filteredData = data.filter((i) => !isNaN(i));
      state.selected_companies = filteredData;
      localStorage.setItem("selected_companies", JSON.stringify(filteredData));
    },
    removeSelectedCompanies: function(state) {
      state.selected_companies = null;
      localStorage.removeItem("selected_companies");
    },
    setSubcompanies: function(state, data) {
      state.subcompanies = data;
      localStorage.setItem("subcompanies", JSON.stringify(data));
    },
  },
  actions: {
    async login(context, userInput) {
      return new Promise((resolve, reject) => {
        userApi
          .loginUser(userInput)
          .then(response => {
            if (response.status === 200) {
              context.commit("setAuthData", response.data);
                 Translation.changeLanguage(
                   response.data.user.locale
                 );
                 moment.locale(
                   response.data.user.locale
                 ); 
              if (response.data.user.phone_verified) {
                context.dispatch("getPermissions").then(_ => {
                  resolve(response.data);
                });
              } else {
                reject({
                  type: "phone_not_verified",
                  err: "Phone not verified"
                });
              }
            }
          })
          .catch(err => {
            if (err.response.headers["www-authenticate"] === "Verify email") {
              reject({
                type: "verify_email",
                response: api.getErrorMessage(err)
              });
            } else if (err.response.headers["www-authenticate"] === "2FA") {
              reject({ type: "2FA", response: api.getErrorMessage(err) });
            }
            reject({ type: "error", response: api.getErrorMessage(err) });
          });
      });
    },
    async getUser(context) {
      try {
        const response = await userApi.getUser();
        if (response.status === 200) {
          context.commit("setUserData", response.data);
          Translation.changeLanguage(
            response.data.locale
          );
          moment.locale(
            response.data.locale
          );
          context.dispatch("getPermissions").then(_ => {
            return true;
          });
        }
      } catch (error) {
        return false;
      }
    },
    async getPermissions(context) {
      return new Promise((resolve, reject) => {
        userApi.getPermissions().then((response) => {
          context.commit("setPermissions", response.data);
          resolve();
        });
      });
    },
    async initializeDevice(context) {
      return new Promise((resolve, reject) => {
        context.state.device.initialize().then(() => {
          context.commit("setDevice", context.state.device);
          resolve()
        });
      });
    },
    toggleE2E(context) {
      context.commit("setE2e_enabled", context.state.e2e_enabled);
    },
    logout: function(context) {
      context.commit("removeAuthData");
    },
    updateCurrentLanguage: function(context, data) {
      context.commit("setCurrentLanguage", data);
    },
    updateSelectedCompanies: function(context, data) {
      context.commit("selectCompanies", data);
    },
    deleteSelectedCompanies: function(context, data) {
      context.commit("removeSelectedCompanies");
    },
    updateSubcompanies: function(context, data) {
      context.commit("setSubcompanies", data);
    },
  },
  modules: {}
});
