<template>
  <ui-tag context="dark_blue" circle v-if="place">
    {{place.title}}
    <i class="subtitle">{{place.subtitle}}</i>
  </ui-tag>
</template>

<script>
import UiTag from "@/components/ui-modules/Tag"
import moment from "moment";
import dashboardApi from '@/api/dashboardApi';
import url_utils from '@/utils/url_utils.js';

export default {
  name: "Places",
  components: {
    UiTag
  },
  props: {
    answer: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      place: null
    }
  },
  mounted() {
    let google_maps_api_key = url_utils.getEnvVariable("google_maps_api_key")
    this.$loadScript(`https://maps.googleapis.com/maps/api/js?key=${google_maps_api_key}&libraries=places`)
    .then(() => {
      this.setupData()
    })
  },
  methods: {
    async setupData() {
      let address = await this.getFormattedAdress(this.answer)
      this.place = address
    },
    getFormattedAdress(place_id) {
      return dashboardApi.getPlace(place_id)
    }
  }
}
</script>

<style>

</style>