import moment from "moment";

const urlParamsToObject = (path = "") => {
  const res = path
    .replace(/^\?/, "")
    .split("&")
    .reduce((dataParam, filterParam) => {
      if (filterParam === "") {
        return dataParam;
      }

      const data = dataParam;
      let [key, value] = filterParam.split("=");
      if (value !== undefined) {
        key = /%\w+/g.test(key) ? decodeURIComponent(key) : key;
        const isArray =
          key === "status_ids" || key === "scores" || key === "tags";
        key = key.replace("[]", "");
        value = decodeURIComponent(value.replace(/\+/g, " "));
        if (isArray) {
          if (!data[key]) {
            data[key] = [];
          }

          data[key].push(value);
        } else {
          data[key] = value;
        }
      }

      return data;
    }, {});
  return res;
};

const checkEmail = (email) => {
  if (email !== "") {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  return false;
};

const isValidURL = (url) => {
  var res = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  );
  return res !== null;
};

const isValidGoogleDriveURL = (url) => {
  const googleDriveRegex =
    /^https:\/\/(?:www\.)?(?:drive|docs)\.google\.com\/?.*/;
  return googleDriveRegex.test(url);
};

const formatURL = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    return "https://" + url;
  }
  return url;
};

const getUrlExtension = (url) => {
  return url.split(/[#?]/)[0].split(".").pop().trim();
};

const getMimeTypeFromExtension = (type) => {
  var mime = require("mime-types");
  return mime.lookup(type);
};

const isNewToken = (token) => {
  let jwtDecode = require("jwt-decode");
  let decoded = jwtDecode(token);
  return decoded.hasOwnProperty("email");
};

const getEnvVariable = (key) => {
  let app_env = JSON.parse(process.env.VUE_APP_API_ENV);
  if (app_env !== null) {
    return app_env[key];
  }
};

const getWebsiteUrl = () => {
  let dashboard_url = window.location.origin;
  return "//" + dashboard_url.substring(dashboard_url.indexOf(".") + 1);
};

const getBaseUrl = (path) => {
  const current_url = window.location.host;

  if (current_url.includes("localhost")) {
    // Remove port before sending the base URL back if exists
    return current_url.split(":")[0];
  }

  let current_url_array = current_url.split(".");
  current_url_array.shift();
  current_url_array.unshift(path);
  const base_url = current_url_array.join(".");

  return base_url;
};

const getDomainUrl = () => {
  return getBaseUrl("");
};

const getAppUrl = () => {
  return getBaseUrl("app");
};

function getCurrentEnv() {
  const currentUrl = window.location.host;
  const currentApiUrl = getEnvVariable("api_url") || "";

  if (currentUrl.includes("localhost")) {
    return currentUrl.split(":")[0];
  }

  if (currentApiUrl) {
    return currentApiUrl.split(".")[1];
  }
  return "";
}

function getTokenName() {
  const currentEnv = getCurrentEnv();

  if (currentEnv === "prod") {
    return "token";
  } else {
    return `token-${getCurrentEnv()}`;
  }
}

const getTokenExpirationDays = (token) => {
  let jwtDecode = require("jwt-decode");
  const tokenDecoded = jwtDecode(token);
  const tokenExpiracyDate = moment.unix(tokenDecoded.exp);
  const tokenDurationDays = tokenExpiracyDate.diff(moment(), "days");
  return tokenDurationDays;
};

export default {
  urlParamsToObject,
  checkEmail,
  isValidURL,
  isValidGoogleDriveURL,
  formatURL,
  getUrlExtension,
  getMimeTypeFromExtension,
  isNewToken,
  getEnvVariable,
  getWebsiteUrl,
  getDomainUrl,
  getAppUrl,
  getCurrentEnv,
  getTokenName,
  getTokenExpirationDays,
};
