<template>
  <div class="type-query-value">
    <p class="type-query-value-title">
      <i
        v-if="value.color"
        class="fas fa-circle text-value-title-color-icon"
        :style="{'--color': `rgb(${value.color})`}" />
      <i
        v-if="value.color_hex"
        class="fas fa-circle text-value-title-color-icon"
        :style="{'--color': value.color_hex}" />
      {{value.value}}
    </p>
  </div>
</template>

<script>
export default {
  name: "TextValue",
  props: {
    value: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
@import "./styles.scss";

.text-value-title-color-icon {
  color: var(--color);
  font-size: 10px;
}
</style>