<template>
  <div :class="rootClass">
    <label v-if="label" class="notme-input--label">{{label}}</label>
    <label
      v-if="rightLabel"
      @click="rightAction"
      class="notme-input--label notme-input--right-label"
    >
      {{rightLabel}}
    </label>
    <label v-else-if="showLength" class="notme-input--label notme-input--right-label">
      {{model ? model.length : 0}}/{{maxLength}}
    </label>
    <div v-if="type === 'password'" class="notme-input--container">
      <input
        class="notme-input--input notme-input--input-password"
        :maxlength="maxLength"
        :type="showPassword ? 'text' : 'password'"
        :placeholder="placeholder"
        :value="model"
        :autoComplete="autocomplete"
        :disabled="disabled"
        @input="$emit('update:model', $event.target.value)"
        @keyup.enter="onEnterPressed"
      />
      <span
        class="notme-input--input-eye"
        @click="setShowPassword"
      >
        <i :class="['fas', showPassword ? 'fa-eye' : 'fa-eye-slash']"/>
      </span>
    </div>
    <input
      v-else
      class="notme-input--input"
      :maxlength="maxLength"
      :type="type"
      :placeholder="placeholder"
      :value="model"
      :autoComplete="autocomplete"
      :disabled="disabled"
      @input="$emit('update:model', $event.target.value)"
      @keyup.enter="onEnterPressed"
      ref="myInput"
    />
    <p v-if="error" class="notme-input--error-message">{{errorMessage}}</p>
  </div>
</template>

<script>
const baseClass = "notme-input"

export default {
  name: "Input",
  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    type: {
      type: String,
      default: "text"
    },
    inputType: {
      type: String
    },
    model: {
      type: String
    },
    className: {
      type: String
    },
    maxLength: {
      type: Number
    },
    showLength: {
      type: Boolean
    },
    rightLabel: {
      type: String
    },
    rightAction: {
      type: Function,
      default: () => {}
    },
    autocomplete: {
      type: String,
      default: "off"
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String
    },
    styles: {
      type: Object
    },
    onEnterPressed: {
      type: Function,
      default: () => {}
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // onEnter: {
    //   type: Function,
    //   default: () => {}
    // },
    // onPhoneChange = (value, data, event, formattedValue) => {},
  },
  mounted() {
    if (this.autoFocus) {
      this.$refs.myInput.focus();
    }
  },
  data() {
    return {
      showPassword: false
    }
  },
  methods: {
    setShowPassword() {
      this.showPassword = !this.showPassword
    }
  },
  computed: {
    rootClass() {
      return [
        baseClass,
        this.className
      ]
    }
  }
};
</script>

<style lang="scss">

.notme-input {
  flex-direction: column;
  text-align: left;

  .notme-input--label {
    margin-bottom: 0.2em;
    font-family: "Campton-Medium";
    font-size: 16px;
    color: $ui-text;
  }

  .notme-input--right-label {
    font-family: "Campton-Medium";
    color: $ui-blue;
    font-size: 14px;
    float: right;
    cursor: pointer;

    &:hover {
      font-family: "Campton-Bold";
    }
  }

  .notme-input--container {
    position: relative;
    display: flex;
  }

  .notme-input--input-eye {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    right: 10px;
    top: 0px;
    bottom: 0px;
  }

  .notme-input--input {
    -webkit-appearance: none;
    outline: none;
    color: $ui-text;
    border: 1px solid $ui-light_grey;
    border-radius: 6px;
    background-color: $ui-card;
    padding: 12px 10px 10px 10px;
    width: 100%;
    font-size: 16px;
    font-family: "Campton-Medium";
    &:focus {
      background-color: $ui-card;
      outline: none;
      border: 1px solid $ui-light_blue;
    }

    &::placeholder {
      color: $ui-subtitle;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $ui-subtitle;
    }

    &::-ms-input-placeholder {
      color: $ui-subtitle;
    }
  }
  .notme-input--input-password {
    padding-right: 40px;
  }

  .notme-input--phone-input {
    padding: 12px 10px 10px 60px;
  }

  .notme-input--dropdown {
    background-color: $ui-card;
    border-radius: 0px;
    border: 1px solid $ui-grey;
    box-shadow: none;
    scrollbar-color: dark;
  }

  .notme-input--dropdown-button {
    color: $ui-text;
    .country {
      &:hover,
      &:focus {
        background-color: $ui-background;
      }
    }
    .country.highlight {
      background-color: $ui-background;
    }
  }

  .notme-input--select-icon {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    margin-top: 16px;
    right: 12px;
    height: 16px !important;
    color: $ui-dark_grey;
  }

  .notme-input--password-icon {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    margin-top: 13px;
    right: 42px;
    height: 16px !important;
    color: $ui-dark_blue;
  }

  .notme-input--error-message {
    text-align: left;
    color: $ui-red;
    margin: 5px 0px;
    font-size: 14px;
  }
}
</style>
