<template>
  <div class="conclusion-details">
    <text-area
      :maxLength="4096"
      :numberOfLines="5"
      :model.sync="textValue"
      :showLength="true"
      :label="
        type === 'report'
          ? $t('report_conclusion_details')
          : $t('accused_outcome_details')
      "
    />
  </div>
</template>

<script>
import TextArea from "@/components/ui-modules/TextArea";
import permissions_utils from "@/utils/permissions_utils";

export default {
  name: "ReportConclusionDetails",
  components: {
    TextArea,
  },
  data() {
    return {
      textValue: "",
    };
  },
  props: {
    conclusionDetails: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  created() {
    this.textValue = this.conclusionDetails;
  },
  watch: {
    textValue(newVal) {
      if (this.type === "report") {
        this.$emit("updateConclusionsDetails", newVal);
      } else {
        this.$emit("updateAccusedOutcomeDetails", newVal);
      }
    },
  },
  methods: {
    can(name) {
      return permissions_utils.can(name);
    },
  },
};
</script>

<style lang="scss">
.conclusion-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  .notme--text-area--label {
    display: flex;
    font-size: 14px;
    text-align: left;
    font-family: "Campton-Medium";
    margin-bottom: 6px;
  }
  .notme--text-area {
    display: flex;
    flex: 1;
    .text-area {
      height: 100%;
      border-radius: 8px;
      font-family: "Campton-Medium";
      line-height: 18px;
      &:focus {
        border-color: $ui-light_blue;
      }
    }
  }
}
</style>
