<template>
  <div class="timeline-comment timeline-event">
    <div class="timeline-icon-wrapper">
      <avatar-name
        :name="user ? `${user.firstname} ${user.lastname}` : $t('anonymous')"
        :id="user ? user.id : 0"
        :size="40"
      />
    </div>
    <div class="timeline-content-container">
      <div class="content d-flex flex-column">
        <div class="title-container">
          <span class="inline-header">
            {{ user ? user.firstname + " " + user.lastname : $t("anonymous") }}
          </span>
          <div class="date inline-header">
            {{ item.updated_on.format("LLL") }}
          </div>
        </div>
        <div class="comment">
          <div v-html="item.data" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarName from "@/components/ui-modules/Avatar/Base";

export default {
  name: "TimelineNote",
  components: {
    AvatarName,
  },
  props: {
    user: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.timeline-comment {
  background-color: $ui-background;
  margin-left: -25px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  border-left-width: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  .timeline-icon-wrapper {
    background-color: transparent !important;
    margin-left: 10px !important;
  }
  .timeline-content-container {
    .content {
      .comment {
        padding: 0px;
        padding-top: 5px;
        padding-right: 10px;
        background-color: $ui-background;
        text-align: left;
        border-radius: 12px;
        font-size: 14px;
        line-height: 16px;
        color: $ui-subtitle;
      }
    }
  }
}
.notme-avatar {
  display: flex;
  margin-left: -4px;
  .notme-avatar-text {
    font-family: "Campton-Medium";
  }
}
</style>
