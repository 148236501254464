<template>
  <authentication-layout
    :title="$t('authentication_section.two_factor_autentication_title')"
    :subtitle="$t('authentication_section.two_factor_autentication_description', {phone: phone})">
    <div class="two-factor">
      <ui-input
        :model.sync="challenge_code"
        :onEnterPressed="enterMethod"
        :maxLength="6"
        type="numeric"
        :label="$t('authentication_section.two_factor_autentication_code')"
      />
      <p class="indications">{{$t("authentication_section.enter_code_received", { seconds: mfa_counter} )}}
        <a :class="['mfa-resend', (!can_resend ? 'is-disabled' : '')]" @click="resendMfA">{{$t("resend")}}</a>.
      </p>
      <ui-button
        :disabled="challenge_code.length !== 6"
        :loading="isLoading"
        full
        @click="enterMethod">
        {{ isLoading ? $t("authentication_section.verifying") : $t("authentication_section.verify")}}
      </ui-button>
      <ui-button
        :disabled="isLoading"
        full
        outline
        @click="cancelMethod">
        {{$t("cancel")}}
      </ui-button>
    </div>
  </authentication-layout>
</template>


<script>
import AuthenticationLayout from "@/layouts/AuthenticationLayout";
import UiInput from "@/components/ui-modules/Input";
import UiButton from "@/components/ui-modules/Buttons/Button";
import userApi from "@/api/userApi";
import challengeApi from "@/api/challengeApi"
import api from "@/api/api";

export default {
  name: "TwoFactor",
  components: {
    AuthenticationLayout,
    UiInput,
    UiButton
  },
  created() {
    if (!this.user_login) {
      if (this.phoneText) {
        this.phone = this.phoneText
        this.startTimer()
      }
      else {
        this.$router.replace({path: "login"})
      }
    }
    else {
      this.postMfa()
    }
  },
  data() {
    return {
      phone: "",
      user_login: this.$route.params.user_login,
      phoneText: this.$route.params.phone,
      phoneNumber: this.$route.params.phoneNumber,
      password: this.$route.params.password,
      isLoading: false,
      challenge_code: "",
      mfa_counter: 60,
      can_resend: false,
      interval: null,
      counter_started: false
    }
  },
  methods: {
    enterMethod() {
      if (this.user_login) {
        this.login2FA()
      }
      else {
        this.verify2FA()
      }
    },
    loginAttempt() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("login", { ...this.user_login, challenge_code: this.challenge_code })
        .then(response => {
          this.$message({
            message: this.$t('authentication_section.welcome_name', { name: response.user.firstname}),
            type: "success"
          });
          resolve()
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    login2FA() {
      this.isLoading = true
      this.loginAttempt()
      .then(response => {
        this.initializeE2E()
      })
      .catch(err => {
        this.isLoading = false
        this.$message.error(err.response);
      })
    },
    verify2FA() {
      this.isLoading = true
      challengeApi.postPhoneVerify(this.challenge_code)
      .then(response => {
        this.$store.commit("setUserData", {...JSON.parse(this.$store.state.auth.user), ...response.data})
        userApi.getPermissions()
          .then(response => {
            this.$store.commit("setPermissions", response.data);
            this.initializeE2E()
          })
      })
      .catch(err => {
        this.isLoading = false
        this.$message.error(api.getErrorMessage(err));
      })
    },
    goToOverview() {
      this.isLoading = false
      this.$router.replace({name: "overview"})
    },
    initializeE2E() {
      /** E3 - Restore private key */
      if (this.$store.getters.isE2Eenabled()) {
        this.initializeDevice().then(_ => {
          this.goToOverview()
        })
      }
      else {
        this.goToOverview()
      }
    },

    startTimer() {
      this.stopTimer()
      this.interval = setInterval(this.countDown, 1000)
    },
    stopTimer() {
      clearInterval(this.interval)
    },
    countDown() {
      var n = this.mfa_counter
      if (!this.counter_started) {
        this.counter_started = true
      } else if (n > 0) {
        n = n - 1
        this.mfa_counter = n
      } else {
        this.can_resend = true
        clearInterval(this.interval)
        this.counter_started = false
      }
    },
    initializeDevice() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("initializeDevice")
          .then(response => {
            this.$store.state.device.cleanup()
            .then(() => {
              this.$store.state.device.hasLocalPrivateKey().then(hasLocalPrivateKey => {
                if (!hasLocalPrivateKey) {
                  this.$store.state.device.restorePrivateKey(this.password);
                }
                else {
                  console.log(hasLocalPrivateKey)
                  console.log("true")
                }
              })
              resolve();
            })
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    postMfa() {
      challengeApi.postMfa(this.user_login.email, this.user_login.password)
        .then(response => {
          this.isLoading = false
          this.phone = response.data.phone
          this.startTimer()
        })
        .catch(err => {
          this.isLoading = false
          this.$message.error(err);
        })
    },
    resendVerificationCode() {
      challengeApi
        .postPhone(this.phoneNumber)
        .then(() => {
          this.$message({
            message: this.$t('authentication_section.two_factor_autentication_code_resent'),
            type: "success"
          });
        })
        .catch(() => {});
    },
    resendMfA() {
      if (this.can_resend) {
        this.resendVerificationCode()
        this.can_resend = false
        this.mfa_counter = 60
        this.startTimer()
      }
    },
    cancelMethod() {
      if (this.user_login) {
        this.cancelMfa()
      }
      else {
        this.cancelVerify()
      }
    },
    cancelMfa() {
      clearInterval(this.interval)
      this.$router.replace({path: "login"})
    },
    cancelVerify() {
      this.$router.replace({path: "verification"})
    }
  }
};
</script>

<style lang="scss">
  @import "~bootstrap/scss/mixins/_breakpoints";
  @import "~bootstrap/scss/_functions";
  @import "~bootstrap/scss/_variables";
  @import '~effect-input/dist/index.css';

  .two-factor {
    .two-factor-header {
      margin-bottom: 30px;
    }
    .indications {
      color: $ui-subtitle !important;
      font-size: 0.8rem;
    }
    .mfa-resend {
      cursor: pointer;
      color: $ui-light_blue;
    }
    .is-disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
  display: flex;
  flex-direction: column;
  gap: 10px;
  }
</style>
