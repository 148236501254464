<template>
  <div class="timeline-resolution timeline-event">
    <div class="timeline-icon-wrapper">
      <div class="timeline-icon">
        <i class="fas fa-clipboard-check" />
      </div>
    </div>
    <div class="timeline-content-container">
      <div class="content d-flex flex-column">
        <div class="title-container">
          <span class="inline-header">
            {{ getUpdatedBy() }}
          </span>
          <div
            v-html="this.getInfoTranslation()"
            class="translation inline-header"
          />
          <div class="date">
            {{ item.updated_on.format("LLL") }}
          </div>
        </div>
        <div class="resolution-content">
          <div class="conclusions_selected">
            <ui-tag
              v-for="(outcome, index) in item.outcomes[0]"
              :key="index"
              context="dark_blue"
              subtle
              round
              small
            >
              {{ getOutcomeTitle(outcome) }}
            </ui-tag>
          </div>
          <p class="text-details">{{ item.data }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiTag from "@/components/ui-modules/Tag";

export default {
  name: "TimelineResolution",
  components: {
    UiTag,
  },
  props: {
    user: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    getInfoTranslation() {
      if (this.item.outcome_type === "report") {
        return this.$t(this.item.index === 0 ? "added_report_conclusion" : "updated_report_conclusion");
      } else if (this.item.outcome_type === "accused") {
        return this.$t(this.item.index === 0 ?"added_accused_outcome" : "updated_accused_outcome");
      } else {
        return "";
      }
    },
    getUpdatedBy() {
      return this.user
        ? this.user.firstname + " " + this.user.lastname
        : this.$t("anonymous");
    },
    getOutcomeTitle(outcome) {
      return this.$t(`outcomes.${this.item.outcome_type}.${outcome}`)
    }
  },
};
</script>

<style lang="scss">
.timeline-resolution {
  .timeline-content-container {
    width: 100%;
    .resolution-content {
      background-color: $ui-background;
      margin-top: 5px;
      margin-bottom: 5px;
      border-radius: 10px;
      border-left-width: 0px !important;
      padding: 12px;
      width: 100%;
      .conclusions_selected {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 4px;
        margin-bottom: 6px;
        font-size: 18px;
        .notme-tag {
          font-size: 14px;
        }
      }
      .text-details {
        margin-top: 10px;
        font-family: "Campton-Medium";
        color: $ui-text;
        text-align: left;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
