<template>
  <report-card
    class="report-notes"
    v-if="can('view report notes')"
    flat
    title="notes"
    description="notes_description"
  >
    <div v-if="can('update assigned report')">
      <vue-editor v-model="new_note" :editor-toolbar="customToolbar" />
      <ui-button context="blue" class="save-notes-button" :disabled="new_note === ''" :loading="isLoading" @click="saveNote">
        {{$t("save_notes")}}
      </ui-button>
    </div>
    <div v-else v-html="new_note"></div>
  </report-card>
</template>

<script>
import ReportCard from "./ReportCard"
import { VueEditor } from "vue2-editor";
import UiButton from "@/components/ui-modules/Buttons/Button";

import reportApi from "@/api/reportApi";
import permissions_utils from "@/utils/permissions_utils";

export default {
  name: "ReportStep",
  components: {
    ReportCard,
    VueEditor,
    UiButton
  },
  props: {
    notes: {
      type: String
    },
    report_id: {
      type: Number
    }
  },
  data() {
    return {
      new_note: "",
      isLoading: false,
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline"],
        [{ align: [] }],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["clean"]
      ]
    };
  },
  created() {
    this.new_note = this.notes;
  },
  methods: {
    saveNote() {
      this.isLoading = true;
      reportApi
        .updateNote(this.report_id, this.new_note)
        .then(response => {
          if (response.status === 200) {
            this.$message({
              message: "Your notes have been saved.",
              type: "success"
            });
            //TODO: v3.2.0 - Remove permission
            if (this.can("view report timeline")) {
              this.$emit("save-notes");
              this.new_note = "";
            }
          }
        })
        .catch(() => {
          this.$message({
            message: "An error occurred while saving your notes.",
            type: "error"
          });
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    can(name) {
      return permissions_utils.can(name);
    }
  }
}
</script>

<style lang="scss">

.report-notes {
  margin-top: 20px;

  .ql-editor {
    font-family: "Campton-Medium";
  }

  .ql-toolbar.ql-snow {
    border-width: 2px 2px 0px 2px;
    border-radius: 0.5em 0.5em 0px 0px;
    border-color: $ui-background;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 2px solid $ui-background;
    border-radius: 0px 0px 0.5em 0.5em;
  }


  .save-notes-button {
    margin-top: 10px;
  }
}

</style>