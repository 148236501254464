<template>
  <div class="pagination-component" v-if="showPagination">
    <media :query="{ minWidth: 576 }">
      <el-pagination
        :current-page="current_page"
        :page-size="limit"
        :total="total"
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
      />
    </media>
    <media :query="{ maxWidth: 576 }">
      <el-pagination
        small
        :current-page="current_page"
        :page-size="limit"
        :total="total"
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
      />
    </media>
  </div>
</template>

<script>
import Media from "vue-media";

export default {
  name: "PaginationComponent",
  components: {
    Media
  },
  props: ["current_page", "limit", "total", "handleCurrentChange"],
  computed: {
    color_style() {
      return ".active { background: " + this.color + " !important }";
    },
    showPagination() {
      return this.total >= this.limit;
    }
  }
};
</script>

<style lang="scss">

.pagination-component {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  .el-pagination .number,
  .el-pagination button:disabled,
  .el-pagination .btn-next {
    background: transparent;
  }
  .el-pagination .btn-quicknext {
    background: transparent;
  }
  .el-pagination .btn-prev {
    background: transparent;
  }
  .el-pagination .btn-quickprev {
    background: transparent;
  }
  .active {
    background: $ui-red !important;
  }
}
</style>
