<template>
  <authentication-layout
    :title="$t('authentication_section.verification_phone_title')"
    :subtitle="$t('authentication_section.verification_phone_description')"
  >
    <div class="verification-phone">
      <vue-phone-number-input
        v-model="phoneText"
        style="margin-bottom: 20px"
        @update="updatePhone"
      />
      <p class="indications">
        {{$t("authentication_section.send_confirmation_code_description")}}
      </p>
      <ui-button
        :disabled="!isPhoneValid"
        :loading="isLoading"
        full
        @click="verifyPhone">
        {{$t("authentication_section.send_confirmation_code_title")}}
      </ui-button>
      <ui-button
        :disabled="isLoading"
        full
        outline
        @click="goToLogin">
        {{$t("cancel")}}
      </ui-button>
    </div>
  </authentication-layout>
</template>

<script>
import AuthenticationLayout from "@/layouts/AuthenticationLayout";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import UiButton from "@/components/ui-modules/Buttons/Button"
import userApi from "@/api/userApi";
import challengeApi from "@/api/challengeApi";
import api from "@/api/api";

export default {
  name: "VerificationPhone",
  components: {
    AuthenticationLayout,
    VuePhoneNumberInput,
    UiButton
  },
  data() {
    return {
      phoneText: "",
      phoneResponse: "",
      phone: "",
      password: this.$route.params.password,
      isPhoneValid: false,
      isLoading: false
    };
  },
  methods: {
    updatePhone(data) {
      this.phone = data.formattedNumber;
      this.isPhoneValid = data.isValid;
    },
    verifyPhone() {
      challengeApi
        .postPhone(this.phone)
        .then(response => {
          this.phoneResponse = response.data.phone;
          this.$router.push({
            name: "TwoFactor",
            params: { phone: this.phoneResponse, phoneNumber: this.phone, password: this.password }
          });
        })
        .catch(err => {});
    },
    goToLogin() {
      this.$store.dispatch("logout").then(_ => {
        this.$router.push({ path: "login" });
      });
    }
  }
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~effect-input/dist/index.css";

.verification-phone {
  .verification-header {
    margin-bottom: 30px;
  }
  .indications {
    color: $ui-subtitle !important;
    font-size: 0.8rem;
  }
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
