import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css'
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue-cookies";
import store from "./store/index";
import { VueMasonryPlugin } from "vue-masonry";
import VueChatScroll from "vue-chat-scroll";
import i18n from "@/utils/i18n/i18n";
import { initializeApp } from "firebase/app";
import config from "./utils/firebase-config";

import EffectInput from "effect-input";

import VuePlaceAutocomplete from 'vue-place-autocomplete';

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
const datepickerOptions = {};

import LoadScript from 'vue-plugin-load-script';

import moment from "moment";
import url_utils from "./utils/url_utils";
import isFlagEnabled from "@/utils/feature_flags";

initializeApp(config);

// Vue.use(VueRouter)

Vue.prototype.moment = moment;
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueCookies, { domain: url_utils.getDomainUrl() });
Vue.use(VueMasonryPlugin);
Vue.use(EffectInput);
Vue.use(VueChatScroll);
Vue.use(AirbnbStyleDatepicker, datepickerOptions);
Vue.use(VuePlaceAutocomplete);
Vue.use(LoadScript);

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

Vue.mixin({
  methods: {
    isFlagEnabled(flag) {
      return isFlagEnabled(flag);
    },
  },
});

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_URL,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    })
  ],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0
});

window.bus = new Vue();

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
