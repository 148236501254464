<template>
  <div class="align-middle" style="height=100%; width:100%">
    <img src="../assets/notfound.png" height="100px" />
    <br /><br />
    <router-link to="/dashboard" tag="el-button"
      ><i class="fas fa-arrow-left" /> Go back to dashboard</router-link
    >
  </div>
</template>

<script>
export default {};
</script>

<style>
html,
body {
  height: 100%;
}
</style>
