<template>
  <span
    :class="rootClass"
    :styles="this.styles"
  >
    <slot></slot>
  </span>
</template>

<script>
const baseClass = "notme-tag"

export default {
  name: "Tag",
  props: {
    context: {
      type: String,
      default: "dark_blue"
    },
    small: {
      type: Boolean,
      default: false
    },
    solid: {
      type: Boolean,
      default: false
    },
    subtle: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    hoverShadow: {
      type: Boolean,
      default: false
    },
    className: {
      type: String
    },
    styles: {
      type: Object
    }
  },
  computed: {
    rootClass() {
      return [
        baseClass,
        this.context && `${baseClass}-${this.context}`,
        this.solid && `${baseClass}-${this.context}-solid`,
        this.subtle && `${baseClass}-${this.context}-subtle`,
        this.circle && `${baseClass}-circle`,
        this.round && `${baseClass}-round`,
        this.clickable && `${baseClass}-clickable`,
        this.hoverShadow && `${baseClass}-shadow`,
        this.small && `${baseClass}-small`,
        this.className
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

.notme-tag {
  display: inline-block;
  border: 1px solid $ui-dark_blue;
  color: $ui-text;
  padding: 4px 14px 2px 14px;
  font-size: 16px;
  transition: 0.1s;
  font-family: "Campton-Medium";
  margin: 4px 8px 4px 0px;

  &:last-child {
    margin-right: 0px;
  }
}

@mixin notme-tag-theme($color) {
  background: none;
  color: $color;
  border: 1px solid $color;
}

@mixin notme-tag-theme-solid($color) {
  background: $color;
  color: $ui-white;
  border: none;
}

@mixin notme-tag-theme-subtle($color, $background) {
  background: $background;
  color: $color;
  border: none;
}

.notme-tag-circle {
  border-radius: 30px;
}

.notme-tag-round {
  border-radius: 6px;
}

.notme-tag-clickable {
  cursor: pointer;
}

.notme-tag-shadow {
  &:hover {
    -webkit-box-shadow: $widget-shadow;
    -moz-box-shadow: $widget-shadow;
    box-shadow: $widget-shadow;
  }
}

.notme-tag-small {
  font-size: 12px;
  font-family: "Campton-Medium";
  padding: 6px 10px 4px 11px;
  margin: 0px 8px 0px 0px;
  box-sizing: border-box;
  white-space: nowrap;
}

.notme-tag-blue                { @include notme-tag-theme($ui-blue);       }
.notme-tag-dark_blue           { @include notme-tag-theme($ui-dark_blue);  }
.notme-tag-light_blue          { @include notme-tag-theme($ui-light_blue); }
.notme-tag-red                 { @include notme-tag-theme($ui-red);        }
.notme-tag-dark_red            { @include notme-tag-theme($ui-dark_red);   }
.notme-tag-light_red           { @include notme-tag-theme($ui-light_red);  }
.notme-tag-green               { @include notme-tag-theme($ui-green);      }
.notme-tag-dark_green          { @include notme-tag-theme($ui-dark_green); }
.notme-tag-yellow              { @include notme-tag-theme($ui-yellow);     }
.notme-tag-dark_yellow         { @include notme-tag-theme($ui-dark_yellow);}
.notme-tag-grey                { @include notme-tag-theme($ui-grey);       }
.notme-tag-dark_grey           { @include notme-tag-theme($ui-dark_grey);  }
.notme-tag-light_grey          { @include notme-tag-theme($ui-light_grey); }

.notme-tag-blue-solid        { @include notme-tag-theme-solid($ui-blue);       }
.notme-tag-dark_blue-solid   { @include notme-tag-theme-solid($ui-dark_blue);  }
.notme-tag-light_blue-solid  { @include notme-tag-theme-solid($ui-light_blue); }
.notme-tag-red-solid         { @include notme-tag-theme-solid($ui-red);        }
.notme-tag-dark_red-solid    { @include notme-tag-theme-solid($ui-dark_red);   }
.notme-tag-light_red-solid   { @include notme-tag-theme-solid($ui-light_red);  }
.notme-tag-green-solid       { @include notme-tag-theme-solid($ui-green);      }
.notme-tag-dark_green-solid  { @include notme-tag-theme-solid($ui-dark_green); }
.notme-tag-yellow-solid      { @include notme-tag-theme-solid($ui-yellow);     }
.notme-tag-dark_yellow-solid { @include notme-tag-theme-solid($ui-dark_yellow);}
.notme-tag-grey-solid        { @include notme-tag-theme-solid($ui-grey);       }
.notme-tag-dark_grey-solid   { @include notme-tag-theme-solid($ui-dark_grey);  }
.notme-tag-light_grey-solid  { @include notme-tag-theme-solid($ui-light_grey); }

.notme-tag-blue-subtle        { @include notme-tag-theme-subtle($ui-blue, $ui-blue-a10);               }
.notme-tag-dark_blue-subtle   { @include notme-tag-theme-subtle($ui-dark_blue, $ui-dark_blue-a10);     }
.notme-tag-light_blue-subtle  { @include notme-tag-theme-subtle($ui-light_blue, $ui-light_blue-a10);   }
.notme-tag-red-subtle         { @include notme-tag-theme-subtle($ui-red, $ui-red-a10);                 }
.notme-tag-dark_red-subtle    { @include notme-tag-theme-subtle($ui-dark_red, $ui-dark_red-a10);       }
.notme-tag-light_red-subtle   { @include notme-tag-theme-subtle($ui-light_red, $ui-light_red-a10);     }
.notme-tag-green-subtle       { @include notme-tag-theme-subtle($ui-green, $ui-green-a10);             }
.notme-tag-dark_green-subtle  { @include notme-tag-theme-subtle($ui-dark_green, $ui-dark_green-a10);   }
.notme-tag-yellow-subtle      { @include notme-tag-theme-subtle($ui-yellow, $ui-yellow-a10);           }
.notme-tag-dark_yellow-subtle { @include notme-tag-theme-subtle($ui-dark_yellow, $ui-dark_yellow-a10); }
.notme-tag-grey-subtle        { @include notme-tag-theme-subtle($ui-grey, $ui-grey-a10);               }
.notme-tag-dark_grey-subtle   { @include notme-tag-theme-subtle($ui-dark_grey, $ui-dark_grey-a10);     }
.notme-tag-light_grey-subtle  { @include notme-tag-theme-subtle($ui-light_grey, $ui-light_grey-a10);   }

</style>