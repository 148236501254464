<template>
  <router-link :to="{ path: '/reports/' + report.id }" class="report-cell-mini">
    <div class="report-container dashboard-cell">
      <div class="report-header">
        <p class="report-title">
          {{ $t("report_id", { id: report.id }) }}
        </p>
        <p
          v-if="
            $store.state.auth.role.title.localeCompare('super admin') === 0 ||
              ($store.state.subcompanies &&
                $store.state.subcompanies.length > 0)
          "
          class="user-name"
        >
          <company-logo
            style="display: inline-block"
            :company="report.company"
            :size="25"
          />
          {{ userFormatter() }}
        </p>
      </div>
      <div class="report-body">
        <div class="tags-container">
          <ui-tag
            :context="getStatusColorName(report.status_id)"
            subtle
            circle
            small
          >
            {{ getStatus(report.status_id) }}
          </ui-tag>
          <ui-tag
            v-if="can('view report score')"
            :context="getScoreColorName(report.score)"
            subtle
            circle
            small
          >
            {{ getScore(report.score) }}
          </ui-tag>
        </div>
        <div class="date-container">
          <p>
            {{ moment(report.submitted_at).format("l") }}
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import UiTag from "@/components/ui-modules/Tag";
import CompanyLogo from "@/components/CompanyLogo";
import report_utils from "@/utils/report_utils";
import permissions_utils from "@/utils/permissions_utils";

export default {
  name: "OverviewSidebar",
  components: {
    UiTag,
    CompanyLogo,
  },
  props: {
    user: {
      type: Object,
    },
    report: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    getStatus(status_id) {
      return this.$t(report_utils.getStatusTitle(status_id));
    },
    getStatusColor(status_id) {
      return report_utils.getStatusColor(status_id);
    },
    getStatusColorName(status_id) {
      return report_utils.getStatusColorName(status_id);
    },
    getScore(score) {
      return this.$t(report_utils.getScoreTitle(score));
    },
    getScoreColor(score) {
      return report_utils.getScoreColor(score);
    },
    getScoreColorName(score) {
      return report_utils.getScoreColorName(score);
    },
    userFormatter() {
      if (!this.report.is_anonymous && this.report.user) {
        return this.report.user.firstname + " " + this.report.user.lastname;
      }
      return this.$t("anonymous");
    },
    can(name) {
      return permissions_utils.can(name);
    },
  },
};
</script>

<style lang="scss" scoped>
.report-cell-mini {
  text-decoration: none;
  .report-container {
    border-radius: 12px;
    transition: all 0.1s ease-in-out;
    padding: 10px;
    text-align: left;
    &:hover {
      transform: scale(1.005);
      cursor: pointer;
    }
    a {
      color: $ui-text;
      text-decoration: none;
    }
    p {
      margin-bottom: 0px;
    }
    .report-header,
    .report-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .report-header {
      padding-bottom: 10px;
      .report-title {
        font-family: "Campton-Bold";
        color: $ui-dark_blue;
      }
      .user-name {
        font-size: 15px;
        font-family: "Campton-Book";
        color: $ui-text;
        .company-logo {
          margin-right: 5px;
        }
      }
    }
    .report-body {
      .date-container {
        p {
          color: $ui-subtitle;
          font-size: 14px;
          font-family: "Campton-Medium";
        }
      }
    }
  }
}
</style>
