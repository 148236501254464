<template>
  <report-card
    title="assignees"
    :count="assignees.length"
    :button_icon="can('assign company report') || can('assign public report') ? 'fas fa-pencil-alt' : ''"
    v-on:action="_updateModalVisibility">
    <div v-if="assignees.length === 0" style="text-align: center">
      <h5 class="title">{{$t("no_assignees")}}</h5>
      <p class="subtitle">{{$t("no_assignees_explanation")}}</p>
    </div>
    <li v-for="(assignee, index) in assignees"
        :key="index">
      <report-user :user="assignee" />
    </li>
    <report-assignees-modal
      :assignees="assignees"
      :showAssignees="showAssignees"
      v-on:close="closeModal"
      v-on:save="saveAssignees"
    />
  </report-card>
</template>

<script>
import ReportCard from "./ReportCard";
import ReportUser from "./ReportUser";
import ReportAssigneesModal from './ReportAssigneesModal.vue';

import permissions_utils from "@/utils/permissions_utils";

export default {
  name: "ReportInformation",
  components: {
    ReportCard,
    ReportUser,
    ReportAssigneesModal
  },
  data() {
    return {
      showAssignees: false
    }
  },
  props: {
    assignees: {
      type: Array
    }
  },
  computed: {
    assigneesToAvatars() {
      let avatars = []
      this.assignees.forEach(element => {
        avatars.push({name: element.firstname + ' ' + element.lastname, id: element.id})
      })
      return avatars
    }
  },
  methods: {
    can(name) {
      return permissions_utils.can(name);
    },
    closeModal() {
      this._updateModalVisibility();
    },
    saveAssignees(value) {
      this.$emit('save-assignees', value)
      this._updateModalVisibility();
    },
    _updateModalVisibility() {
      this.showAssignees = !this.showAssignees;
    }
  }
}
</script>

<style lang="scss">

</style>