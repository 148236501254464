<template>
  <div class="timeline-event">
    <div class="timeline-icon-wrapper">
      <div class="timeline-icon">
        <i class="fas fa-paperclip" />
      </div>
    </div>
    <div class="timeline-content-container">
      <div class="content">
        <div class="title-container">
          <span class="inline-header">
            {{ user ? user.firstname + " " + user.lastname : $t("anonymous") }}
          </span>
          <div
            v-html="this.getDocTranslation()"
            class="translation inline-header"
          />
          <div class="date">
            {{ item.updated_on.format("LLL") }}
          </div>
          <div class="evidence" v-if="!item.deletion">
            <div v-for="(file, index) in item.files" :key="index">
              <report-file
                v-if="file"
                :report_file="file"
                v-on:preview-file="previewFile(file)"
                :isTimeline="true"
              />
              <div v-else class="empty-evidence">
                <i class="fas fa-file empty-evidence-icon" />
                <i class="fas fa-times empty-evidence-cross" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <file-preview-modal
      v-if="showFile"
      :selectedFile="selectedFile"
      :report_status_id="report_status_id"
      :closeModal="closeModal"
      :downloadAction="downloadAction"
      :isTimeline="true"
    />
  </div>
</template>

<script>
import ReportFile from "../views/dashboard/Report/components/ReportFile.vue";
import report_utils from "@/utils/report_utils";
import FilePreviewModal from "./FilePreviewModal.vue";

export default {
  name: "TimelineDoc",
  components: {
    ReportFile,
    FilePreviewModal,
  },
  props: {
    user: {
      type: Object,
    },
    users: {
      type: Object,
    },
    item: {
      type: Object,
    },
    report_status_id: {
      type: Number,
    }
  },
  data() {
    return {
      showFile: false,
      selectedFile: null,
    };
  },
  methods: {
    getDocTranslation() {
      return this.$t(
        this.item?.deletion ? "deleted_evidence" : "sent_new_evidence",
        {
          count: this.item.files ? this.item.files.length : 0,
        }
      );
    },
    previewFile(file) {
      let file_type = report_utils.getFileTypeFromURL(file.file);
      if (report_utils.isImage(file_type)) {
        this.selectedFile = file;
        this._updateModalVisibility();
      } else {
        this.downloadAction(file.file);
      }
    },
    _updateModalVisibility() {
      this.showFile = !this.showFile;
    },
    downloadAction() {
      window.open(this.selectedFile.file, "_blank");
    },
    beautifyDate(date) {
      return this.moment(date).format("lll");
    },
    closeModal() {
      this._updateModalVisibility();
    },
    userFormatter(uploader) {
      return uploader.firstname + " " + uploader.lastname;
    },
  },
};
</script>

<style lang="scss">
.timeline-event {
  .evidence {
    display: flex;
    flex: row;
    flex-wrap: wrap;
    .report-file {
      margin-top: 0px;
      border-width: 0px;
      position: relative;
      margin-bottom: 0px;
      padding-left: 0px;
      .download-btn-container {
        position: absolute !important;
        margin-right: 20px;
        margin-top: 5px;
      }
      .report-file--content-container {
        display: none;
      }
      .report-file--img-container {
        width: 120px;
        height: 120px;
        .report-file-img {
          max-height: 120px;
          border-radius: 7px;
        }
      }
    }
    .empty-evidence {
      display: flex;
      margin-top: 10px;
      width: 120px;
      height: 120px;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-right: 10px;
      border-radius: 7px;
      background-color: $ui-background;
      .empty-evidence-icon {
        font-size: 50px;
        color: $ui-grey;
      }
      .empty-evidence-cross {
        color: $ui-background;
        position: absolute;
        margin-top: 10px;
        font-size: 20px;
      }
    }
  }
}
</style>
