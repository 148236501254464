<template>
  <div class="search-bar-container">
    <div class="search-bar" ref="searchBarContainerRef">
      <i class="fas fa-search side-icon" />
      <div class="search-bar-content">
        <div class="search-bar-scroll-container">
          <ul class="query-list">
            <search-query-cell v-for="(item, index) in searchQueries"
              v-bind:key="index"
              :category="item.category"
              :value="item.value"
              v-on:removeQuery="removeQuery(index)"
            />
            <search-query-cell v-if="categorySelected" :category="categorySelected" />
            <li class="query-list-item">
              <input
                class="search-input"
                type="search"
                :placeholder="placeholder"
                v-model="model"
                @blur.stop="onBlur"
                @focus="onFocus"
                @input="onChange"
                @keydown="onKeyDown"
                @click="onClick"
                aria-label="Search"
                ref="searchFocus"
              />
            </li>
          </ul>
        </div>
      </div>
      <i
        v-if="model !== '' || searchQueries.length > 0 || categorySelected"
        class="fas fa-times-circle side-icon search-clear-button"
        @click="clearSearch"
      />
    </div>
    <dropdown-category-container
      :showDropdown="focused && categoriesFiltered.length > 0 && !categorySelected"
      :categoriesFiltered="categoriesFiltered"
      :positionX="currentDrodpownPositionX"
      v-on:select="selectCategory"
    />
    <dropdown-value-container
      :showDropdown="focused && categorySelected !== null"
      :categorySelected="categorySelected"
      :searchQuery="model"
      :positionX="currentDrodpownPositionX"
      :searchQueries="searchQueries"
      v-on:select="selectValue"
      v-on:open="openReport"
    />
    <!-- <dropdown-container :showDropdown="focused && categorySelected !== null">
      <ui-loading v-if="isSearchLoading" big context="blue" />
      <ul class="value-list">
        <report-category-cell :report_id="122" :status_id="2" submitted_at="12/12/2022" />
        <report-category-cell :report_id="122" :status_id="2" submitted_at="12/12/2022" />
        <text-category-cell color="#f40a12" text="test test test test test test test test test test" />
        <text-category-cell text="test test test test test test test test test test" />
        <user-category-cell :user="{ firstname: 'test', lastname: 'test', role: 'OOO', id: 122 }" />
      </ul>
    </dropdown-container> -->
  </div>
</template>

<script>
import UiLoading from "@/components/ui-modules/Loading";
import DropdownCategoryContainer from "./DropdownCategoryContainer"
import DropdownValueContainer from "./DropdownValueContainer"
import SearchQueryCell from "./SearchQueryCell";

import search_categories from "./search_categories"

export default {
  name: "Search",
  props: {
    placeholder: {
      type: String
    },
    searchQueries: {
      type: Array
    }
  },
  components: {
    UiLoading,
    DropdownCategoryContainer,
    DropdownValueContainer,
    SearchQueryCell,
  },
  data() {
    return {
      model: "",
      focused: false,
      categories: search_categories,
      categorySelected: null,
      isSearchLoading: true,
      preventDropdownBlur: false,
      currentDrodpownPositionX: 0,
      searchBarContainerPositionX: 0
    };
  },
  computed: {
    categoriesFiltered() {
      let new_arr = []
      let dependencies = []

      this.categories.forEach(category => {
        if (this.searchQueries.map(a => a.category).includes(category)) {
          if (category.multiple) {
            new_arr.push(category)
          }
          if (category.dependency) {
            dependencies.push(category.dependency)
          }
        }
        else {
          new_arr.push(category)
        }
      });

      return new_arr.filter(element => {
        return element["title"].toLowerCase().includes(this.model.toLowerCase()) //show results based on search text
              && !dependencies.includes(element["type"])
      });
    }
  },
  mounted() {
    this.updateCurrentDropdownPositionX(400)
  },
  methods: {
    selectCategory(category) {
      this.preventDropdownBlur = true
      this.categorySelected = category;
      this.model = "";
      this.$refs.searchFocus.focus()
      this.updateCurrentDropdownPositionX()
      setTimeout( () => {
        this.focused = true
      }, 50)
    },
    selectValue(value) {
      this.$emit("addToSearchQueries", {category: this.categorySelected, value: value})
      this.categorySelected = null;
      this.model = "";
      this.$refs.searchFocus.focus()
      this.focused = false;
      this.updateCurrentDropdownPositionX()
      this.preventDropdownBlur = false;
    },
    openReport(value) {
      this.$router.push(`/reports/${value}`)
    },
    clearSearch() {
      this.$emit("updateSearchQueries", [])
      this.model = "";
      this.focused = false;
      this.categorySelected = null;
      this.preventDropdownBlur = false
      this.updateCurrentDropdownPositionX()
    },
    removeQuery(index) {
      this.$emit("removeIndexSearchQueries", index)
      this.updateCurrentDropdownPositionX()
    },
    onFocus() {
      this.focused = true
    },
    onClick() {
      if (!this.focused) {
        this.focused = true
      }
    },
    onChange(event) {
      if (!this.focused) {
        this.focused = true
      }
      if (this.categorySelected && this.categorySelected.type === "report") {
        this.model = this.model.replace(/[^0-9]/g, '')
      }
    },
    onKeyDown(event) {
      if (event.keyCode === 8 && this.model === "") { //delete button
        if (this.categorySelected) {
          this.model = this.$t(this.categorySelected.title)
          this.categorySelected = null
        }
        else if (this.searchQueries.length > 0) {
          let lastQuery = this.searchQueries[this.searchQueries.length - 1]
          this.categorySelected = lastQuery.category
          this.searchQueries.pop()
        }
        this.preventDropdownBlur = true
        this.focused = true
        this.updateCurrentDropdownPositionX()
      }
    },
    onBlur() {
      setTimeout(() => {
        if (this.preventDropdownBlur) {
          this.preventDropdownBlur = false
          this.focused = true
        }
        else {
          if (this.categorySelected) {
            this.categorySelected = null
          }
          this.focused = false
        }
      }, 200)
    },
    updateCurrentDropdownPositionX(delay = 50) {
      setTimeout(() => {
        this.searchBarContainerPositionX = this.$refs.searchBarContainerRef.getBoundingClientRect().x
        this.currentDrodpownPositionX = this.$refs.searchFocus.getBoundingClientRect().x - this.searchBarContainerPositionX
      }, delay)
    }
  }
}
</script>

<style lang="scss">
.search-bar-container {
  position: relative;
  display: flex;
  flex-grow: 1;
  .search-bar {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
    background-color: $ui-lighter_grey;
    border-radius: 6px;
    padding: 6px 14px;
    .search-bar-content {
      flex: 1;
      position: relative;
      min-width: 0;
      height: 28px;
      .search-bar-scroll-container {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        .query-list {
          display: flex;
          flex: 1;
          padding-left: 0px;
          position: relative;
          margin-bottom: 0;
          width: 1px;
          white-space: nowrap;
          gap: 10px;
          list-style: none;
          .query-list-item {
            border-radius: 4px;
            padding: 0px 4px;
            &:last-child {
              flex: 1;
              max-width: inherit;
            }
          }
        }
        .search-input {
          background-color: $ui-lighter_grey;
          position: relative;
          font-family: "Campton-Medium";
          min-width: 220px;
          border: none;
          padding: 0;
          height: 28px;
          line-height: inherit;
          width: 100%;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .search-clear-button {
      cursor: pointer;
      color: $ui-grey;
      transition: .25s ease-in-out;
      &:hover {
        color: $ui-text;
      }
    }
    .side-icon {
      width: 20px;
    }
  }

  .search-box-fade-enter-active,
  .search-box-fade-leave-active {
    transition: opacity .25s;
  }
  .search-box-fade-enter,
  .search-box-fade-leave-to {
    opacity: 0;
  }
}
</style>