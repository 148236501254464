import axios from "axios";
import { SUPPORTED_LANGUAGES, SUPPORTED_LANGUAGES_HUMAN, DEFAULT_LANGUAGE } from "./languages/languages_translations";
import i18n from "./i18n";
import store from "@/store/index";

const Translation = {
  get defaultLanguage() {
    return DEFAULT_LANGUAGE;
  },
  get supportedLanguages() {
    return SUPPORTED_LANGUAGES;
  },
  get supportedLanguagesHuman() {
    return SUPPORTED_LANGUAGES_HUMAN;
  },
  get currentLanguage() {
    if (store.getters.getCurrentLanguage() !== null) {
      i18n.locale = store.getters.getCurrentLanguage()
    }
    return i18n.locale;
  },
  set currentLanguage(lang) {
    i18n.locale = lang;
    store.dispatch("updateCurrentLanguage", lang)
  },
  getUserSupportedLang() {
    const userPreferredLang = Translation.getUserLang();

    // Check if user preferred browser lang is supported
    if (Translation.isLangSupported(userPreferredLang.lang)) {
      return userPreferredLang.lang;
    }
    // Check if user preferred lang without the ISO is supported
    if (Translation.isLangSupported(userPreferredLang.langNoISO)) {
      return userPreferredLang.langNoISO;
    }
    return Translation.defaultLanguage;
  },
  getUserLang() {
    const lang =
      window.navigator.language ||
      window.navigator.userLanguage ||
      Translation.defaultLanguage;
    return {
      lang: lang,
      langNoISO: lang.split("-")[0]
    };
  },
  setI18nLanguageInServices(lang) {
    Translation.currentLanguage = lang;
    axios.defaults.headers.common["Accept-Language"] = lang;
    document.querySelector("html").setAttribute("lang", lang);
    return lang;
  },
  changeLanguage(lang) {
    if (!Translation.isLangSupported(lang))
      return Promise.reject(new Error("Language not supported"));
    if (i18n.locale === lang) {
      Translation.currentLanguage = lang;
      return Promise.resolve(lang); // has been loaded prior
    }
    return Translation.loadLanguageFile(lang).then(msgs => {
      i18n.setLocaleMessage(lang, msgs.default || msgs);
      return Translation.setI18nLanguageInServices(lang);
    });
  },
  loadLanguageFile(lang) {
    return import(
      /* webpackChunkName: "lang-[request]" */ `./languages/${lang}.json`
    );
  },
  isLangSupported(lang) {
    return Translation.supportedLanguages.includes(lang);
  },
  getBrowserLocale(options = {}) {
    const defaultOptions = { countryCodeOnly: true }
    const opt = { ...defaultOptions, ...options }

    const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language

    if (!navigatorLocale) {
      return undefined
    }

    const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim()

    return trimmedLocale
  }

  // routeMiddleware(to, from, next) {
  //   // Load async message files here
  //   const lang = to.params.lang;
  //   if (!Trans.isLangSupported(lang))
  //     return next(Trans.getUserSupportedLang());
  //   return Trans.changeLanguage(lang).then(() => next());
  // },
  // i18nRoute(to) {
  //   return {
  //     ...to,
  //     params: { lang: this.currentLanguage, ...to.params }
  //   };
  // }
};

export { Translation };
