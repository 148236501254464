<template>
  <h6 class="report-other-text">
    {{setupData(answer)}}
  </h6>
</template>

<script>
export default {
  name: "Other",
  props: {
    answer: {
      type: String,
      default: null
    }
  },
  methods: {
    setupData(data) {
      return `Other: ${data}`
    },
  }
}
</script>

<style lang="scss">

.report-other-text {
  color: $ui-text;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  margin: 10px 0px 0px 0px;
}

</style>