import api from "./currentApi";
import external_api from "./api";
import constants from "./constants";
import url_utils from "@/utils/url_utils";
import store from "../store/index";
import google_place_type from "../utils/google_place_type";

const getDashboard = (start_date, end_date, company_ids) => {
  return api
    .request_logged(constants.DASHBOARD_URL, {
      start_date: start_date,
      end_date: end_date,
      company_ids: company_ids
    })
};

const getHarassers = params => {
  return api.request_logged(constants.HARASSERS_URL, params)
};

const getBehaviors = params => {
  return api.request_logged(constants.BEHAVIORS_URL, params)
};

const getVictims = params => {
  return api.request_logged(constants.VICTIMS_URL, params)
};

const getLocations = params => {
  return api.request_logged(constants.LOCATIONS_URL, params)
};

const getPlaces = params => {
  return api
    .request_logged(constants.PLACES_URL, params)
};

const getChatToken = () => {
  return api.request_logged(constants.CHAT_TOKEN_URL)
};

const getMessageToken = id => {
  return api.request_logged(constants.REPORTS_URL + "/" + id + "/token");
};

const getPlace = async (place_id) => {
  return new Promise((resolve, reject) => {
    const mapElement = document.getElementById("map")
    const placesService = new window.google.maps.places.PlacesService(mapElement);
    placesService.getDetails({placeId: place_id}, (results, status) => {
      if (status === "OK" && results) {
        if (!results.types.includes("locality")
          && !results.types.includes("neighborhood")) {
          resolve({
            status,
            title: results.name,
            subtitle: results.formatted_address,
            icon: results.icon
          });
        }
        else {
          resolve({
            status,
            title: results.formatted_address,
            subtitle: google_place_type.getAdministrativeArea(results),
            icon: results.icon
          });
        }
      } else {
        reject({
          error: "Place not found",
          status
        });
      }
    })
  })
}

export default {
  getDashboard,
  getHarassers,
  getBehaviors,
  getVictims,
  getLocations,
  getPlaces,
  getChatToken,
  getMessageToken,
  getPlace
};
