<template>
  <div
    :class="
      clearDates
        ? ['analytics', 'date-picker-container']
        : ['date-picker-container']
    "
  >
    <div v-if="clearDates" class="date-picker-header">
      <h6 class="range-label">{{ $t("date_range") }}</h6>
      <a v-if="value !== ''" class="clear-filter" @click="clearDates">{{
        $t("clear")
      }}</a>
    </div>
    <el-date-picker
      :value="value"
      type="daterange"
      align="right"
      unlink-panels
      :clearable="false"
      range-separator="-"
      :start-placeholder="$t('start_date')"
      :end-placeholder="$t('end_date')"
      :picker-options="pickerOptions"
      :format="momentFormat"
      @input="handle_change($event)"
    >
    </el-date-picker>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DatePicker",
  props: {
    value: {
      type: Array,
    },
    clearDates: {
      type: Function,
    },
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: this.$t("last_week"),
            onClick(picker) {
              const end = new Date();
              const start = new Date(
                moment()
                  .subtract(1, "week")
                  .add(1, "day")
                  .format("YYYY-MM-DD")
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t("last_month"),
            onClick(picker) {
              const end = new Date();
              const start = new Date(
                moment()
                  .subtract(1, "month")
                  .add(1, "day")
                  .format("YYYY-MM-DD")
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t("last_x_months", {x: "3"}),
            onClick(picker) {
              const end = new Date();
              const start = new Date(
                moment()
                  .subtract(3, "months")
                  .add(1, "day")
                  .format("YYYY-MM-DD")
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t("last_x_months", {x: "6"}),
            onClick(picker) {
              const end = new Date();
              const start = new Date(
                moment()
                  .subtract(6, "months")
                  .add(1, "day")
                  .format("YYYY-MM-DD")
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t("last_x_months", {x: "12"}),
            onClick(picker) {
              const end = new Date();
              const start = new Date(
                moment()
                  .subtract(12, "months")
                  .add(1, "day")
                  .format("YYYY-MM-DD")
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t("all_time"),
            onClick(picker) {
              const end = new Date();
              const start = new Date("1/1/2018");
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      momentFormat: this.moment
        .localeData()
        .longDateFormat("L")
        .replaceAll("D", "d")
        .replaceAll("Y", "y"),
    };
  },
  methods: {
    handle_change(command) {
      this.$emit("changeDate", command);
    },
  },
};
</script>

<style lang="scss">
.date-picker-container {
  .date-picker-header {
    display: flex;
    justify-content: space-between;
    .clear-filter {
      float: right;
      font-size: 12px;
      font-weight: 700;
      padding-top: 2px;
      color: $ui-red;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: $ui-dark_blue;
      }
    }
  }
  .el-input__inner,
  .el-range-input {
    cursor: pointer;
    color: $ui-dark_blue;
    .el-range__close-icon {
      display: none;
    }
  }
}
.analytics {
  padding-right: 0px;
  padding-left: 0px;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
