import constants from "./constants";
import api from "./currentApi";
import url_utils from "../utils/url_utils";

const getGenerateReport = (report_id, format) => {
  const base_url = url_utils.getEnvVariable("flash_api_url");
  const url = `${constants.REPORTING_REPORT_URL}/${report_id}/${constants.GENERATE_URL}`;
  return api.request_logged_custom_blob(base_url, url, {
    file_format: format,
  });
};

export default {
  getGenerateReport,
};
