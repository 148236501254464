import api from "./api";
import constants from "./constants";

const postChatLogin = () => {
  return api.request_logged_post(constants.CHAT_LOGIN_URL, { });
};

const postChatReportLogin = (id) => {
  return api.request_logged_post(`${constants.FIREBASE_LOGIN_URL}${id}/login` , { });
};

export default {
  postChatLogin,
  postChatReportLogin
};
