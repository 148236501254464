<template>
  <ul class="report-search-list">
    <ui-loading v-if="loading" />
    <template v-else>
      <text-category-cell
        key="openReportKey"
        v-if="searchQuery"
        :text="`Go to report #${searchQuery}`"
        v-on:select="$emit('select', +searchQuery)"
      />
      <report-category-cell
        v-for="(result, index) in searchResults"
        :key="index"
        :report="result"
        v-on:select="$emit('select', result.id)"
      />
    </template>
  </ul>
</template>

<script>
import UiLoading from "@/components/ui-modules/Loading";
import ReportCategoryCell from "../CategoryCell/ReportCategoryCell"
import reportApi from "@/api/reportApi";
import TextCategoryCell from '../CategoryCell/TextCategoryCell'

export default {
  name: "ReportSearchList",
  components: {
    UiLoading,
    ReportCategoryCell,
    TextCategoryCell
  },
  props: {
    categorySelected: {
      type: Object
    },
    searchQuery: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: false,
      data: []
    }
  },
  computed: {
    searchResults() {
      return this.data
    }
  },
  watch: {
    async searchQuery(newv, oldv) {
      this.searchQuery = newv.replace(/\D+/g, '');
    }
  },
  async created() {
    this.data = await this.fetchData()
  },
  methods: {
    async fetchData(searchQuery) {
      let query = {offset: 0, limit: 20}
      if (searchQuery) {
        query["query"] = searchQuery
      }
      this.loading = true
      try {
        const response = await reportApi.getReports(query)
        if (response.status === 200) {
          this.loading = false
          return response.data.reports
        }
      } catch (error) {
          this.loading = false
          return []
      }
    },
    searchData() {
    }
  }

}
</script>

<style>

</style>