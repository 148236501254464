<template>
  <div class="company-logo-container col-12 d-flex align-items-center p-0 m-0" v-on:click="$emit('action')">
    <p class="p-0 m-0">
      <company-logo
        v-if="true"
        :company="company"
        :size="logoSize"
      />{{ company.name }}
    </p>
    <ui-button
      v-if="type === 'add'"
      context="grey"
      class="right-btn"
      smallSquare
      hoverShadow
      outline
      @click="$emit('action')"
      >
      <i class="fas fa-plus"></i>
    </ui-button>
    <ui-button
      v-else-if="type === 'remove'"
      context="red"
      class="right-btn"
      smallSquare
      hoverShadow
      outline
      @click="$emit('action')"
      >
      <i class="fas fa-trash-alt"></i>
    </ui-button>
  </div>
</template>

<script>
import CompanyLogo from "@/components/CompanyLogo";
import UiButton from "@/components/ui-modules/Buttons/Button";

export default {
  name: "ReportCompany",
  components: {
    CompanyLogo,
    UiButton
  },
  methods: {
  },
  props: {
    company: {
      type: Object,
      default: null
    },
    type: {
      type: String, //TYPES: add, remove
      default: null
    },
    logoSize: {
      type: Number,
      default: 44
    }
  }
}
</script>

<style lang="scss">

.company-logo-container {
  cursor: pointer;
  p {
    font-family: "Campton-Medium";
    color: $ui-text;
  }
  .company-logo {
    margin-right: 10px !important;
  }
  .right-btn {
    width: 24px;
    height: 24px;
    padding: 4px;
    margin-left: auto;
    font-size: 10px;
  }
}

</style>