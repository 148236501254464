<template>
  <authentication-layout
    :title="$t('authentication_section.sign_up')"
    :subtitle="$t('authentication_section.sign_up_description', { email: this.$route.query.email })"
  >
    <div class="signup">
      <form class="signup-form">
        <div class="row">
          <div class="col-6">
            <ui-input
              :model.sync="signup_data.firstname"
              type="name"
              :label="$t('user.first_name')"
            />
          </div>
          <div class="col-6">
            <ui-input
              :model.sync="signup_data.lastname"
              type="name"
              :label="$t('user.last_name')"
            />
          </div>
        </div>
        <ui-input
          :model.sync="signup_data.job_title"
          type="text"
          :label="$t('user.job_title')"
        />
        <input :value="this.$route.query.email" type="email" autocomplete="email" hidden />
        <ui-input
          :model.sync="signup_data.password"
          type="password"
          autocomplete="current-password"
          :label="$t('user.password')"
        />
        <ui-input
          :model.sync="signup_data.password_confirmation"
          type="password"
          :label="$t('user.confirmation_password')"
        />
        <ui-checkbox v-model="signup_data.termsAccepted">
          {{$t("authentication_section.i_agree_to_the")}}
          <a :href="`${getWebsiteUrl()}/terms-of-use`" target="_blank"
            > {{$t("authentication_section.terms_of_use")}} </a
          >
          {{$t("and")}}
          <a :href="`${getWebsiteUrl()}/privacy-policy`" target="_blank"
            > {{$t("authentication_section.privacy_policy")}}
          </a>.
        </ui-checkbox>
      </form>
      <ui-button
        :disabled="!isFormCompleted"
        :loading="isLoading"
        full
        @click="signUp">
        {{$t("authentication_section.sign_up")}}
      </ui-button>
    </div>
  </authentication-layout>
</template>

<script>
import AuthenticationLayout from "@/layouts/AuthenticationLayout";
import UiInput from "@/components/ui-modules/Input";
import UiButton from "@/components/ui-modules/Buttons/Button";
import UiCheckbox from "@/components/ui-modules/Checkbox";
import userApi from "@/api/userApi";
import api from "@/api/api";
import url_utils from '@/utils/url_utils';

export default {
  name: "Signup",
  components: {
    AuthenticationLayout,
    UiInput,
    UiButton,
    UiCheckbox
  },
  data() {
    return {
      signup_data: {
        firstname: "",
        lastname: "",
        password: "",
        job_title: "",
        password_confirmation: "",
        termsAccepted: false
      },
      isLoading: false
    };
  },
  created() {
    if (!this.isQueryValid()) {
      this.$router.push("/login");
    }
  },
  computed: {
    isFormCompleted() {
      return this.signup_data.firstname !== ""
        && this.signup_data.lastname !== ""
        && this.signup_data.job_title !== ""
        && this.signup_data.password !== ""
        && this.signup_data.password.length >= 8
        && this.signup_data.password_confirmation !== ""
        && this.signup_data.password_confirmation.length >= 8
        && this.signup_data.password === this.signup_data.password_confirmation
        && this.signup_data.termsAccepted === true
    },
  },
  methods: {
    signUp() {
      if (!this.validateSignUp()) {
        return
      }

      if (!this.isLoading) {
        this.isLoading = true;
        let sign_up = {
          firstname: this.signup_data.firstname,
          lastname: this.signup_data.lastname,
          email: this.$route.query.email,
          password: this.signup_data.password,
          job_title: this.signup_data.job_title,
          terms_accepted: this.signup_data.termsAccepted,
          privacy_accepted: this.signup_data.termsAccepted,
          token: this.$route.query.token,
          locale: this.$store.state.language
        };

        userApi
          .signUp(sign_up)
          .then(response => {
            this.isLoading = false;
            if (response.status === 200) {
              this.$message({
                message: this.$t("authentication_section.welcome_account_created"),
                type: "success"
              });
              /*E3*/
              if (this.$store.getters.isE2Eenabled()) {
                this.$device.register(sign_up.password)
                .then(() => {
                  this.$device.backupPrivateKey(this.signup_data.password);
                })
                .catch(e => console.error('error: ', e));
              }
              this.$router.push("/login");
            }
          })
          .catch(err => {
            this.isLoading = false;
            this.$message({
              message: api.getErrorMessage(err),
              type: "error"
            });
          });
      }
    },
    handleLabelClick() {
      this.$refs.input.focus();
    },
    getWebsiteUrl() {
      return url_utils.getWebsiteUrl(this.$store.state.language)
    },
    validateSignUp() {
      if (!this.isFormCompleted) {
        this.$message({
          message: this.$t("authentication_section.error_signup_data"),
          type: "error"
        });
        return false
      }
      if (!this.isTermsAccepted()) {
        this.$message({
          message: this.$t("authentication_section.error_terms_of_use"),
          type: "error"
        });
        return false
      }
      if (!this.isPasswordConfirmed()) {
        this.$message({
          message: this.$t("authentication_section.error_password_confirmation"),
          type: "error"
        });
        return false
      }
      if (!this.isPasswordValid()) {
        this.$message({
          message: this.$t("authentication_section.error_password_length"),
          type: "error"
        });
        return false
      }
      return true
    },
    isQueryValid() {
      return this.$route.query.token
        && this.$route.query.email
    },
    isTermsAccepted() {
      return this.signup_data.termsAccepted
    },
    isPasswordConfirmed() {
      return this.signup_data.password === this.signup_data.password_confirmation
    },
    isPasswordValid() {
      return this.signup_data.password.length >= 8
    }
  }
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~effect-input/dist/index.css";

.signup {
  .signup-header {
    text-align: left;
    margin-bottom: 30px;
  }
  .el-checkbox__label {
    white-space: normal;
    vertical-align: middle;
    word-break: break-all;
    word-wrap: break-word;
  }
  .signup-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
