<template>
  <report-card
    title="organization"
    :button_icon="can('update report company') ? 'fas fa-pencil-alt' : ''"
    v-on:action="_updateModalVisibility">
    <div v-if="!company" style="text-align: center">
      <h5 class="title">{{$t("no_organization")}}</h5>
      <p class="subtitle">{{$t("no_organization_explanation")}}</p>
    </div>
    <div v-else>
        <report-company :company="company" />
    </div>
    <report-companies-modal
      :company="company"
      :showCompanies="showCompanies"
      v-on:close="closeModal"
      v-on:save="saveCompany"
    />
  </report-card>
</template>

<script>
import ReportCard from "./ReportCard";
import ReportCompany from "./ReportCompany";
import ReportCompaniesModal from './ReportCompaniesModal.vue';

import permissions_utils from "@/utils/permissions_utils";

export default {
  name: "ReportCompanies",
  components: {
    ReportCard,
    ReportCompany,
    ReportCompaniesModal
  },
  data() {
    return {
      showCompanies: false
    }
  },
  props: {
    company: {
      type: Object
    }
  },
  computed: {
  },
  methods: {
    can(name) {
      return permissions_utils.can(name);
    },
    closeModal() {
      this._updateModalVisibility();
    },
    saveCompany(value) {
      this.$emit('save-company', value)
      this._updateModalVisibility();
    },
    _updateModalVisibility() {
      this.showCompanies = !this.showCompanies;
    }
  }
}
</script>

<style lang="scss">

</style>