import store from "../store/index";
import url_utils from "./url_utils";

const can = (name = "") => {
  let role = store.state.auth.role;
  if (name !== "") {
    if (role) {
      if (role.title === "super admin") {
        return true;
      }
      if (role.permissions) {
        if (role.permissions.includes(name)) {
          return true;
        }
      }
      return false;
    }
    return false;
  }
  return false;
};

const getDefaultRoute = () => {
  let initialRoute = "";
  const userRole = getUserRole();
  switch (userRole) {
    case "editor":
      window.open(`https://${url_utils.getAppUrl()}/feed`, '_self')
      break;
    case "viewer":
      initialRoute = "/analytics";
      break;
    case "subscriber":
      initialRoute = "/reports";
      break;
    default:
      initialRoute = "/index";
  }
  return initialRoute;
};

const getUserRole = () => {
  return store.state.auth.role.title;
};

export default {
  can,
  getDefaultRoute,
  getUserRole
};
