const chart_colors = {
  "13": ["#657786", "#441412", "#e3d2c9", "#c1473a"],
  "3": "#1F5688",
  "2": "#441412",
  "7": "#5f9e83",
  low: "#96C3D9",
  med: "#1F5688",
  high: "#c1473a",
  submitted: "#441412",
  opened: "#1F5688",
  "under review": "#c1473a",
  resolved: "#5f9e83",
};

const translationsHandler = (translatedText) => {
  switch (translatedText) {
    case "low":
    case "bajo":
    case "faible":
    case "niedrig":
    case "basso":
      return chart_colors.low;
    case "med":
    case "medio":
    case "moyen":
    case "mittel":
      return chart_colors.med;
    case "high":
    case "alto":
    case "élevé":
    case "hoch":
      return chart_colors.high;
    case "submitted":
    case "enviados":
    case "soumis":
    case "eingereicht":
    case "presentata":
      return chart_colors.submitted;
    case "opened":
    case "abierto":
    case "ouvert":
    case "geöffnet":
    case "aperta":
      return chart_colors.opened;
    case "under review":
    case "en investigación":
    case "en cours de traitement":
    case "in prüfung":
    case "sotto indagine":
      return chart_colors["under review"];
    case "resolved":
    case "resueltos":
    case "résolu":
    case "aufgelöst":
    case "risolta":
      return chart_colors.resolved;
  }
};

const getChartColors = (report) => {
  const complex_chart_ids = [14, 15];
  if (!complex_chart_ids.includes(report.id)) {
    return chart_colors[report.id];
  } else {
    const colors = report.data.map((data) => {
      return translationsHandler(data[1].toLowerCase());
    });
    return colors;
  }
};

export default {
  getChartColors,
};
