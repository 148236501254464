<template>
  <dropdown-container :showDropdown="showDropdown" :positionX="positionX">
    <report-search-list
      v-if="categorySelected && categorySelected.type === 'report'"
      :searchQuery="searchQuery"
      v-on:select="openReport"
    />
    <status-search-list
      v-else-if="categorySelected && categorySelected.type === 'status'"
      :searchQuery="searchQuery"
      :searchQueries="searchQueries"
      v-on:select="selectValue"
    />
    <score-search-list
      v-else-if="categorySelected && categorySelected.type === 'score'"
      :searchQuery="searchQuery"
      :searchQueries="searchQueries"
      v-on:select="selectValue"
    />
    <anonymous-search-list
      v-else-if="categorySelected && categorySelected.type === 'anonymous'"
      :searchQuery="searchQuery"
      v-on:select="selectValue"
    />
    <unassigned-search-list
      v-else-if="categorySelected && categorySelected.type === 'unassigned'"
      :searchQuery="searchQuery"
      v-on:select="selectValue"
    />
    <assignee-search-list
      v-else-if="categorySelected && categorySelected.type === 'assignee'"
      :searchQuery="searchQuery"
      v-on:select="selectValue"
    />
    <date-range-search-list
      v-else-if="categorySelected && categorySelected.type === 'date'"
      :searchQuery="searchQuery"
      v-on:select="selectValue"
    />
    <tag-search-list
      v-else-if="categorySelected && categorySelected.type === 'tag'"
      :searchQuery="searchQuery"
      :searchQueries="searchQueries"
      v-on:select="selectValue"
    />
  </dropdown-container>
</template>

<script>
import UiLoading from "@/components/ui-modules/Loading";
import DropdownContainer from "../DropdownContainer"

import ReportSearchList from "./SearchList/ReportSearchList";
import StatusSearchList from './SearchList/StatusSearchList';
import ScoreSearchList from './SearchList/ScoreSearchList';
import AnonymousSearchList from "./SearchList/AnonymousSearchList";
import UnassignedSearchList from "./SearchList/UnassignedSearchList";
import AssigneeSearchList from "./SearchList/AssigneeSearchList";
import DateRangeSearchList from "./SearchList/DateRangeSearchList";
import TagSearchList from './SearchList/TagSearchList';

export default {
  name: "DropdownValueContainer",
  components: {
    UiLoading,
    DropdownContainer,
    ReportSearchList,
    StatusSearchList,
    ScoreSearchList,
    AnonymousSearchList,
    UnassignedSearchList,
    AssigneeSearchList,
    DateRangeSearchList,
    TagSearchList
  },
  props: {
    showDropdown: {
      type: Boolean,
      default: false
    },
    categorySelected: {
      type: Object
    },
    positionX: {
      type: Number
    },
    searchQuery: {
      type: String,
      default: ""
    },
    searchQueries: {
      type: Array
    }
  },
  data() {
    return {
      isSearchLoading: true,
      searchResults: []
    }
  },
  methods: {
    selectValue(item) {
      this.$emit("select", item)
    },
    openReport(item) {
      this.$emit("open", item)
    }
  }
}
</script>

<style lang="scss">


  .dropdown-value-list {
    margin: 0;
    padding: 10px 0px;
    list-style: none;
  }

</style>