<template>
  <ui-button v-if="onClick" class="button-arrow" hoverShadow context="lighter_grey" @click="onClick">
    {{ $t(title) }} <span v-if="counter">{{ counter }}</span> <i :class="arrowDirection" />
  </ui-button>
  <ui-button v-else class="button-arrow" hoverShadow context="lighter_grey">
    {{ $t(title) }} <i :class="arrowDirection" />
  </ui-button>

</template>

<script>
import UiButton from "@/components/ui-modules/Buttons/Button";

export default {
  name: "ButtonArrow",
  components: {
    UiButton
  },
  props: {
    title: {
      type: String
    },
    value: {
      type: Boolean,
      default: false
    },
    counter: {
      type: Number
    },
    onClick: {
      type: Function
    }
  },
  computed: {
    arrowDirection() {
      return `fas ${
        this.value ? "fa-chevron-up" : "fa-chevron-down"
      } arrow-spacing`;
    }
  }
};
</script>

<style lang="scss">
.button-arrow {
  @media (max-width: 576px) {
    margin: 0 0 8px;
    display: block;
    width: 100%;
  }
  .arrow-spacing {
    margin-left: 8px;
  }
  span {
    background-color: $ui-blue;
    color: $ui-white;
    font-size: 15px;
    padding: 3px 5px 1px 5px;
    margin-right: -5px;
    margin-left: 2px;
    border-radius: 3px;
  }
}
</style>
