<template>
  <div class="dashboard-header">
    <div v-if="user" class="greeting-container">
      <h6 class="greeting-text">{{ getGreeting() }}</h6>
      <h4 class="user-text">{{ showUser() }}</h4>
    </div>
    <div :class="$parent.childrens ? ['header'] : ['header', 'header-bottom']">
      <div class="header-title">
        <h4 class="title">{{ $t(this.title) }}</h4>
        <h6 v-if="user" class="period-label">{{$t('dashboard_sections.overview.selected_period')}}</h6>
        <information-popover
          v-if="description || descriptionLink"
          :description="description"
          :descriptionLink="descriptionLink">
          <i class="fas fa-info-circle info-circle" />
        </information-popover>
      </div>
      <div :class="['right-content', isSideFlex ? 'right-content-flex' : '']">
        <slot />
      </div>
    </div>
    <div class="children-elements" v-if="$parent.childrens">
      <ul class="settings-ul">
        <li v-for="children in $parent.childrens" v-bind:key="children.name" class="settings-li">
          <router-link
            class="children-router-link"
            :to="{ name: children.name }"
            >{{ $t(children.title) }}</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import InformationPopover from "@/components/InformationPopover";

export default {
  name: "DashboardHeader",
  components: {
    InformationPopover
  },
  props: {
    title: {
      type: String
    },
    user: {
      type: Object
    },
    description: {
      type: String
    },
    descriptionLink: {
      type: String,
      default: null
    },
    isSideFlex: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showUser() {
      return this.user.firstname + " 👋";
    },
    getGreeting() {
      let date = new Date();
      let currentHour = date.getHours();
      if (currentHour >= 5 && currentHour < 12) {
        return this.$t("dashboard_sections.overview.greeting_morning");
      } else if (currentHour >= 12 && currentHour < 17) {
        return this.$t("dashboard_sections.overview.greeting_afternoon");
      } else if (currentHour >= 17 || currentHour < 5) {
        return this.$t("dashboard_sections.overview.greeting_evening");
      }
    }
  }
};
</script>

<style lang="scss">
.dashboard-header {
  .greeting-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    .greeting-text {
      color: $ui-subtitle;
      text-transform: uppercase;
      font-size: 14px;
    }
    .user-text {
      color: $ui-text;
      margin-bottom: 4px;
    }
  }
  .header {
    @media (min-width: 576px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .header-title {
      display: flex;
      flex-direction: row;
    }

    .title {
      text-align: left;
      color: $ui-text;
      font-family: "Campton-Medium" !important;
      font-size: 32px;
    }

    .period-label {
      align-self: center;
      margin-bottom: 0px;
      margin-left: 10px;
      font-family: "Campton-Medium" !important;
    }

    .right-content {
      margin-left: auto;
      margin-right: 0;

      .notme-button  {
        margin-left: 10px;
      }

      @media (max-width: 576px) {
        .is-circle {
          border-radius: 25px;
        }
      }

      @media (max-width: 576px) {
        margin-bottom: 15px;
        display: block;
        .notme-button  {
          margin-left: 0px;
        }
      }
    }

    .right-content-flex {
      margin-left: 0;
      flex: 1;
    }

    .el-dropdown {
      @media (max-width: 576px) {
        width: 100%;
      }
    }

    .info-circle {
      color: $ui-dark_grey;
      align-self: center;
      margin-left: 10px;
      padding-top: 10px;
      font-size: 18px;
    }
  }
  .children-elements {
    display: block;
    margin-bottom: 10px;
    border-bottom: 1px solid $ui-light_grey;
    li {
      display: inline;
      padding-right: 10px;
    }

    .children-router-link {
      padding-bottom: 3px;
      color: $ui-text;
    }

    .children-router-link:hover,
    .children-router-link.router-link-active,
    .children-router-link.router-link-exact-active {
      text-decoration: none;
      border-bottom: 2px solid $ui-blue;
    }

    .children-router-link.router-link-active,
    .children-router-link.router-link-exact-active {
      color: $ui-blue;
      font-family: "Campton-Bold";
    }
  }

  .header-bottom {
    margin-bottom: $layout-padding-size;
    border-bottom: 1px solid $ui-light_grey;
  }
}
</style>
