import api from "./currentApi";
import constants from "./constants";

const postMfa = (email, password) => {
  return api.request_logged_post(constants.MFA_URL, {
    email: email,
    password: password
  });
};

const postPhone = phone => {
  return api.request_logged_post(constants.PHONE_URL, { phone: phone });
};

const postPhoneVerify = challenge_code => {
  return api.request_logged_post(constants.PHONE_VERIFY_URL, {
    challenge_code: challenge_code
  });
};

export default {
  postMfa,
  postPhone,
  postPhoneVerify
};
