<template>
  <div class="dashboard-cell accused-cell">
    <div class="accused-container">
      <div class="left-content">
        <avatar-initial
          :id="accused.id"
          :name="accused.name"
          color="31, 86, 137"
        />
      </div>
      <a style="width: 100%; align-self: center;">
        <div class="row accused-row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-12 my-auto">
            <p class="accused-title">
              {{ accused.name }}
            </p>
          </div>
        </div>
      </a>
      <div class="right-content">
        <router-link
          :to="{ path: '/reports?harasser_id=' + accused.id }"
        >
          <ui-tag context="light_blue" subtle circle hoverShadow small>{{getNumberReport(accused.count)}}</ui-tag>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import UiTag from "@/components/ui-modules/Tag";
import AvatarInitial from "@/components/AvatarInitial";

export default {
  name: "AccusedCell",
  components: {
    UiTag,
    AvatarInitial
  },
  props: ["accused"],
  methods: {
    getNumberReport(nb_report) {
      return nb_report + " " + (nb_report !== 1 ? this.$t("reports_noun") : this.$t("report_noun"));
    }
  }
};
</script>

<style lang="scss">

.accused-cell {
  transition: all 0.1s ease-in-out;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  a {
    color: $ui-text;
    text-decoration: none;
  }
  p {
    margin-bottom: 0px;
  }

  .accused-row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .accused-container {
    display: flex;
    flex-direction: row;

    .left-content {
      padding-left: 20px;
      padding-right: 20px;
      align-self: center;
    }
    .right-content {
      padding-left: 20px;
      padding-right: 20px;
      align-self: center;
    }

    .accused-title {
      text-align: left;
      color: $ui-text;
      font-family: "Campton-Medium";
      font-size: 20px;
    }
  }
}
</style>
